import { CSSProperties, Suspense } from "react";

/*
 Children must be dynamically import with option suspense is true.

 Example:

 const FormLogIn = dynamic(() => import("../forms/FormLogIn"), {
    suspense: false,
 });

*/

interface Waiting {
  children: any;
  style?: CSSProperties;
}

const Waiting = ({ children, style }: Waiting) => (
  <Suspense fallback={<div style={style}>`Loading...`</div>}>
    <div style={style}>{children}</div>
  </Suspense>
);

export default Waiting;
