"use client";
import { GlobalContext } from "./context-types";

import { createContext, useContext } from "react";
import { defaultValue as authDefaultValue } from "@/modules/auth/interface";
import { defaultValue as cartDefaultValue } from "@/modules/cart/interface";
import { PropsConfirmModal } from "@/libs/modal/confirm/useConfirm";
import { OptionsModal } from "@/libs/modal/useModal";
import {DEVICE_SIZE} from '@/types/interfaces/primitives';

/*
  This place for Dependency Injections of modules interface
*/

export const defaultValueDefaultContext: GlobalContext = {
  geo: { latitude: -1, longitude: -1, codeCountry: undefined },
  settings: {
    public: {
      hideProductsFilter: false,
    },
    seller: {
      allowMediaGallery: false,
    },
  },
  // @ts-ignore
  providers: {},
  isNotFound: false,
  setAuthToken: (data: { access: string; refresh: string }, remember?: boolean) => {},
  eraseAuthToken: () => {},
  isReady: false,
  isAuthorized: false,
  isSeller: false,
  isBusinessRegistered: false,
  isMobileSize: false,
  isSmallSize: false,
  deviceSize: DEVICE_SIZE.BIG,
  isMacOS: false,
  isIOS: false,
  isSafari: false,
  isMediumHeight: false,
  locale: "",
  languages: {
    data: [],
    list: [],
    main: undefined,
    onChange: (code: string) => {},
    onChangeSeller: (code: string) => {},
  },
  currency: {
    value: "",
    priceByRate: (price: any) => 0,
    symbol: "",
    list: [],
    onChange: (code: string) => {},
  },
  translate: (text: string) => text,
  modules: {
    auth: authDefaultValue,
    cart: cartDefaultValue,
  },
  toast: {
    info: (text: string, autoClose?: boolean) => {},
    success: (text: string, autoClose?: boolean) => {},
    warning: (text: string, autoClose?: boolean) => {},
    error: (text: string, autoClose?: boolean) => {},
    update: (text: string, autoClose?: boolean) => {},
  },
  openModal: (content: any, options?: OptionsModal) => {},
  backModal: () => {},
  closeModal: () => {},
  openConfirm: (args: PropsConfirmModal) => {},
};

export const globalContext = createContext<GlobalContext>(defaultValueDefaultContext);

export const useClientContext = () => {
  return useContext(globalContext);
};

export default useClientContext;
