export const AnalyticsEventType = "analytics-event";

export type AnalyticsEventDetail = EventAddToCart | EventBeginCheckout | EventPurchase | EventAddToWishlist;

// https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag#add_to_cart_item
export interface EventAddToCart {
  event_name: AnalyticsEvents.addToCart;
  currency: string;
  // Set value to the sum of (price * quantity) for all items in items.
  value: number;
  items: {
    item_id: string;
    item_name: string;
    discount: number;
    item_brand?: string;
    item_category: string;
    item_variant?: string;
    price: number;
    quantity: number;
  }[];
}

export interface EventBeginCheckout {
  event_name: AnalyticsEvents.beginCheckout;
  currency: string;
  // Set value to the sum of (price * quantity) for all items in items.
  value: number;
  coupon?: string;
  items: {
    item_id: string;
    item_name: string;
    discount: number;
    item_brand?: string;
    item_category: string;
    item_variant?: string;
    price: number;
    quantity: number;
  }[];
}

export interface EventPurchase {
  event_name: AnalyticsEvents.purchase;
  currency: string;
  // Set value to the sum of (price * quantity) for all items in items.
  value: number;
  transaction_id: string;
  coupon?: string;
  shipping?: number;
  tax?: number;
  items: {
    item_id: string;
    item_name: string;
    discount: number;
    item_brand?: string;
    item_category: string;
    item_variant?: string;
    price: number;
    quantity: number;
  }[];
}

export interface EventAddToWishlist {
  event_name: AnalyticsEvents.addToWishlist;
  currency: string;
  value: number;
  items: {
    item_id: string;
    item_name: string;
    discount: number;
    item_brand?: string;
    item_category: string;
    item_variant?: string;
    price: number;
    quantity: number;
  }[];
}

export enum AnalyticsEvents {
  addToCart = "add_to_cart",
  addToWishlist = "add_to_wishlist",
  beginCheckout = "begin_checkout",
  purchase = "purchase",
}
