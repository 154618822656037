import { ValueRoute } from "@/types/modules/interface-types";

export const baseRoute = "cart";

const routesCart = {
  home: () => `/${baseRoute}`,
  details: () => `/${baseRoute}/details`,
  checkoutStatusSuccess: () => `/${baseRoute}/checkout-status?status=success`,
};

export default routesCart;
