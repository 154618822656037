import { UseInterface } from "types/modules/interface-types";
import { AuthInterface } from "./interface-types";
import { FormRecoveryPasswordProps } from "./forms/FormRecoveryPassword";

import useActions from "./hooks/useActions";
import routesAuth, { baseRoute } from "./constants/routesAuth";
import { useCallback, useMemo } from "react";
import authRequests from "./requests";
import {IApiAuthError} from '@/types/interfaces/account';
import { FormTwoFactorAuthCodeProps } from "./forms/FormTwoFactorAuthCode";

export const moduleName = "auth";

export const defaultValue: AuthInterface = {
  moduleName,
  baseRoute,
  routes: routesAuth,
  actions: {
    openLogIn: (args?: { asSeller?: boolean }) => {},
    onSuccessLogin: () => {},
    openSignUp: () => {},
    openRecoveryPassword: (args: FormRecoveryPasswordProps) => {},
    openRestoreAccount: (args?: { email: string }) => {},
    openChangeEmail: () => {},
    openNewPassword: () => {},
    openPhone: () => {},
    logout: () => {},
    openConfirmEmail: (args?: { email: string }) => {},
    openTwoFactorAuthCode: (props: FormTwoFactorAuthCodeProps) => {},
  },
};

export const useInterface = (args: UseInterface): AuthInterface => {
  const { eraseAuthToken } = args;
  const actions = useActions(args);

  const logout = useCallback(() => {
    authRequests
      .logout()
      .finally(() => eraseAuthToken())
      // .catch((err) => {
      //   // The logout endpoint not implemented on the backend
      //   if (typeof err === "string" && err.startsWith("<!")) {
      //     eraseAuthToken();
      //   }
      // });
  }, [eraseAuthToken]);

  return useMemo(
    () => ({
      moduleName,
      baseRoute,
      routes: routesAuth,
      actions: { ...actions, logout },
    }),
    [actions, logout]
  );
};

export default useInterface;
