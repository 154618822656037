"use client";
import Waiting from "@/components/basic/Waiting";
import dynamic from "next/dynamic";
import { useMemo } from "react";
import { ArgsOpenModal, UseInterface } from "types/modules/interface-types";
import { FormRecoveryPasswordProps } from "../forms/FormRecoveryPassword";
import { FormTwoFactorAuthCodeProps } from "../forms/FormTwoFactorAuthCode";

const FormLogIn = dynamic(() => import("../forms/FormLogIn"), {
  ssr: false,
  suspense: true,
});

const ConfirmLogin = dynamic(() => import("../components/ConfirmLogin"), {
  ssr: false,
  suspense: true,
});

const FormSignUp = dynamic(() => import("../forms/FormSignUp"), {
  ssr: false,
  suspense: true,
});

const FormRecoveryPassword = dynamic(
  () => import("../forms/FormRecoveryPassword"),
  {
    ssr: false,
    suspense: true,
  }
);

const FormRestoreAccount = dynamic(
  () => import("../forms/FormRestoreAccount"),
  {
    ssr: false,
    suspense: true,
  }
);

const FormChangeEmail = dynamic(() => import("../forms/FormChangeEmail"), {
  ssr: false,
  suspense: true,
});

const FormNewPassword = dynamic(() => import("../forms/FormNewPassword"), {
  ssr: false,
  suspense: true,
});

const ConfirmEmailNotification = dynamic(() => import("../components/ConfirmEmailNotification/index"), {
  ssr: false,
  suspense: true,
});

const FormConfirmEmail = dynamic(() => import("../forms/FormConfirmEmail"), {
  ssr: false,
  suspense: true,
});

const FormTwoFactorAuthCode = dynamic(() => import("../forms/FormTwoFactorAuthCode"), {
  ssr: false,
  suspense: true,
});

const useActions = ({ openModal }: UseInterface) => {
  return useMemo(() => {
    return {
      openLogIn: (args?: { email?: string, asSeller?: boolean, closeAllPrevious?: boolean }) => {
        // @ts-ignore
        openModal(() => {
          return (
            <Waiting>
              <FormLogIn {...args} />
            </Waiting>
          );
        }, {closeAllPrevious: args?.closeAllPrevious });
      },
      onSuccessLogin: () => {
        // @ts-ignore
        openModal(() => {
          return (
            <Waiting>
              <ConfirmLogin />
            </Waiting>
          );
        });
      },
      openSignUp: () => {
        // @ts-ignore
        openModal(() => {
          return (
            <Waiting>
              <FormSignUp />
            </Waiting>
          );
        });
      },
      openRecoveryPassword: (args: FormRecoveryPasswordProps) => {
        // @ts-ignore
        openModal(() => {
          return (
            <Waiting>
              <FormRecoveryPassword {...args} />
            </Waiting>
          );
        });
      },
      openRestoreAccount: (args?: { email: string }) => {
        // @ts-ignore
        openModal(() => {
          return (
            <Waiting>
              <FormRestoreAccount />
            </Waiting>
          );
        });
      },
      // TODO not implemented backend
      openChangeEmail: (props = {}) => {
        // @ts-ignore
        openModal(() => {
          return (
            <Waiting>
              <FormChangeEmail {...props} />
            </Waiting>
          );
        });
      },
      openNewPassword: (props = {}) => {
        // @ts-ignore
        openModal(() => {
          return (
            <Waiting>
              <FormNewPassword {...props} />
            </Waiting>
          );
        });
      },
      openPhone: () => {},
      // @ts-ignore
      openRestoreAccount: () => {},
      openConfirmEmail: (args?: { email: string, onSuccess?: () => void, autosend?: boolean, staySamePage?: boolean }) => {
        // @ts-ignore
        openModal(() => {
          return (
            <Waiting>
              {/*<ConfirmEmailNotification {...args} />*/}
              <FormConfirmEmail {...args} />
            </Waiting>
          );
        }, {
          closeAllPrevious: true
        });
      },
      openTwoFactorAuthCode: (props: FormTwoFactorAuthCodeProps) => {
        // @ts-ignore
        openModal(() => {
          return (
            <Waiting>
              <FormTwoFactorAuthCode {...props} />
            </Waiting>
          );
        });
      },
    };
  }, [openModal]);
};

export default useActions;
