import { AnalyticsEventDetail, AnalyticsEventType } from "./AnalyticsConstants";

const analyticsEmitter = (detail: AnalyticsEventDetail) => {
  console.log("analyticsEmitter", detail);
  const event = new CustomEvent(AnalyticsEventType, {
    bubbles: true,
    detail,
  });
  document.dispatchEvent(event);
};

export default analyticsEmitter;
